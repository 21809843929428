a{
	color:inherit;
	
	&:hover,
	&:focus{
		color: inherit;
		text-decoration: none;
		outline: none;
	}
}

.paginacao{
	text-align: center;
}

.title-internas{
	margin-top:0;
}

.page-header{
	padding-bottom: 0;
	border-bottom: none;

	h1,
	h3{
		font-family: "oswaldlight";
		font-style: italic;
	}

	h1{
		font-size: 46px;
		color: #BBBDBF;
	}

	h3{
		font-size: 18px;
		color: #3B3B3B;
	}
}