// min-width: 992px and max-width: 1199px
@include media-breakpoint-only(lg) {
	.navbar-topo.ai-c{
		align-items:flex-start !important;
	}

	.navbar-topo{
		padding-bottom:50px;
		position:relative;
	}

	.navbar-header{
		width:35%;
		align-self:flex-start;
	}

	.navbar-right{
		width:65%;
		padding-left:30px;
		// position:relative;
	}

	.parceiros{
		display:flex;
		justify-content:flex-end;
		align-items:center;
		position:relative;
		margin-right:-15px;

		& > * {
			position:relative;
			z-index:1;
			margin-bottom:0;
		}

		&:before{
			content:'';
			display: block;
			position:absolute;
			width: 100%;
			height:100%;
			left:0;
			@include gradient-x(transparent,#FFF,0%,30%)
		}

	}

	.navbar-address{
		display:flex;
		flex-wrap:wrap;
		justify-content:space-between;

		& > *{
			display:inline-flex;
			align-items:center;
			margin:0 10px 10px 10px;
		}
		.redes-sociais{
			width:100%;
			justify-content:flex-end;
			
			.icon{
				margin-left:10px;
			}
		}

	}

	.navbar-nav{
		position:absolute;
		left:0;
		bottom:0;
		width:100%;
		padding:0 15px;
		margin-right:-15px;
	}

	.navbar-toggle{
		display: none;
	}

	#menu{
		display:flex;
		justify-content:space-between;

		li{
			display: block;
		}

		a{
			padding:5px 10px 0 10px;

			&:after{
				content:'';
				height:12px;
				width:100%;
				display: block;
				transform:skew(-15deg);
			}
		}

		.active a:after,
		a:hover:after{
			background: #FFCC2A
		}
	}

	// CONTEUDO
	.produto-content{
		.btn{
			width:100%;

			& + .btn{
				margin-top: 15px;
			}
		}
	}
}