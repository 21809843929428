.paginacao{
	margin-top:20px;
	margin-bottom:30px;
	display: flex;
	justify-content: center;
	align-items: center;

	> *{
		display: inline-block;
		padding: 6px;

		font-family: "open_sansregular";
		font-size: 18px;
		color: rgba(#616161, .6);
	}
	
	.active,
	*:hover{
		text-decoration:none;
		color: rgba(#616161, 1);
	}
}
