.rodape{
	position: relative;
	z-index: 9;
	background: $bg-footer;
	padding-top:20px;

	.form-contato{
		padding: 15px;
		box-shadow: 0 0 10px 5px #000;
		background: #383838;

		.form-group{
			label{
				font-family: "open_sanslight";
				font-size: 18px;
				color: #EEE;
			}

			.form-control{
				border-radius: 0;
				box-shadow: none;
				min-height: 45px;
			}
		}
	}

	.logo-footer{
		margin-bottom: 20px;
		// @media (min-width: $screen-md-min){
		// 	margin-bottom: 80px;
		// }
	}

	.contato-footer{
		.icon{
			display: block;
		}
		font-family: "open_sanslight";
		font-size: 18px;
		color: #BABDBF;
	}

	.redes-sociais{
		margin-left: -5px;
		margin-right: -5px;
		text-align: right;

		a{
			display: inline-block;
			margin: 0 5px;
		}

		span{
			display: block;
			font-family: "open_sanslight";
			font-size: 18px;
			color: #BABDBF;
			font-style: italic;
		}
	}

	.creditos{
		margin-top: 20px;
		padding: 10px 0;
		font-family: "open_sanslight";
		font-size: 11px;
		color: #BBBDBF;
		background: #000;

		span{
			margin: 0 15px;
		}
	}
}