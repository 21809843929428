.card{
	background:#fff;
	border:1px solid #ccc;
	box-shadow:0 0 8px rgba(#000,0.6);
	margin-bottom:30px;

	.title-card{
		margin-bottom:10px;

		h1{
			margin:0;
			font-size:20px;
		}
	}

	.content{
		padding:10px;
	}

	.footer-card{
		border-top:1px solid #ddd;
		padding-top:10px;
	}

	&.evento{
		.foto{
			width:220px;
			margin:0 auto;
		}

		// @media (min-width:$screen-sm-min){
		// 	display:flex;

		// 	.title-card{
		// 		@include resumo(20px,1.3,2);
		// 	}

		// 	.desc{
		// 		@include resumo(15px,1.3,5);
		// 		margin-bottom:18px;
		// 	}

		// 	.content{
		// 		// height:220px;
		// 	}
		// }

		// @media (max-width:$screen-xs-max){
		// 	max-width:220px;
		// 	margin-left:auto;
		// 	margin-right:auto;
		// 	margin-bottom:30px;

		// 	.desc,.title-card{
		// 		margin-bottom:10px;
		// 	}
		// }
	}
}