.navbar-topo{
	padding-top: 20px;
	box-shadow: 0 5px 20px 1px #000;
	background-size:cover;
	background-image: url(#{$imgs}/bg-header.png);
	background-position:center top;
	border-bottom:5px solid #010101;

	&.affix{
		width: 100%;
		z-index: 1000;

		top: var(--top, 0px);
	}

	.parceiros{
		// border-top:2px solid #FFF;
		// border-left:2px solid #FFF;
		// border-bottom:2px solid #FFF;
		padding:10px;
		margin-bottom:30px;
	}

	.navbar-address{
		padding-bottom:20px;
		position:relative;
		color:#FFF;
		font-style: italic;
		font-size:14px;
		font-family: open_sansregular;

		&:after{
			content:'';
			display: block;
			width: calc(100% + 15px);
			height:5px;
			@include gradient-x(#110D00,#FFCC2A);
			position:absolute;
			bottom:0;
			left:0;
		}
	}

	#menu{
		&.in{
			display: block !important;
			padding-left: 0px;
			list-style: none;
			margin-bottom: 0px;
		}
		a{
			color:#FFF;
			font-family: oswaldregular;
			font-size:20px;
			display: block;

			@include media-breakpoint-down (md) {
				margin-left: 10px;
			}

			&:hover{
				text-decoration:none;
			}
		}
	}
}

.navbar-toggle{
	@extend .btn;
	@include button-variant(#FFCC2A,transparent,#FFCC2A);
}