// min-width: 576px and max-width: 767px
@include media-breakpoint-only(sm) {
	.navbar-topo{

		.navbar-header{
			width:100%;
			text-align:center;
		}

		.navbar-right{
			width:100%;
		}


		.parceiros{
			margin-left:-15px;
			margin-right:-15px;
			background:#FFF;
			display:flex;
			justify-content:space-between;

			figure{
				margin-bottom:0;
				max-width:23%;
			}
		}

	}

	.navbar-address{
		display:flex;
		flex-wrap:wrap;

		& > *{
			display:flex;
			align-items:center;
			padding:0 10px 10px 10px;
		}

		span{

			&:nth-child(1){
				order:1;
				width:50%;
			}

			&:nth-child(2){
				order:3;
				width:100%;
				margin-top:-8px;
			}

			&:nth-child(3){
				order:4;
				width:100%;
			}
		}

		.redes-sociais{
			width:50%;
			order:2;
			justify-content:flex-end;

			.icon{
				margin-left:15px;
			}
		}
	}

	.navbar-nav{
		position:relative;

		.navbar-toggle{
			position:absolute;
			right:0;
			bottom:100%;
			margin-bottom:40px;
		}
	}
	
	.affix{
		.navbar-fixed{
			position:fixed;
			width:100%;
			top:0;
			left:0;
			z-index:999;
			animation:fadeInDown 0.4s;
			background: #000000;
			padding:10px 15px;
			text-align:right;
		}

		.navbar-toggle{
			position:relative;
			bottom:0;
			margin-bottom:0;
		}

	}
	
	#menu{
		margin-left:-15px;
		margin-right:-15px;
		text-align:left;
		background: #FFCC2A;
		margin-top:10px;
		border-top:1px solid lighten(#000000, 15%);

		a{
			padding:5px 15px;
		}

		.active a,
		a:hover{
			background: #F89931;
		}
	}

	// CONTEUDO
	.produto-content{
		.btn{
			width:100%;

			& + .btn{
				margin-top: 15px;
			}
		}
	}
}