.banners{
	position: relative;
	border-bottom: 5px solid #000;

	.carousel-indicators{
		background: #FFCC2A;
		padding:5px;

		@media (max-width: 991px){
			position:relative;
			width:100%;
			bottom:0;
			margin-bottom:0;
			left:0;
			margin-left:0;
			
			display:flex;
			justify-content:center;
			align-items:center;
		}

		@media (min-width: 992px){
			width: 240px;
			bottom:0;
			margin-bottom:0;
			left:50%;
			margin-left:-140px;
			display:flex;
			justify-content:center;
			align-items:center;
		}

		li{
			width:18px;
			height:18px;
			background: #616161;
			margin:0 5px 0 5px;

			&.active{
				background: #D96D00;
			}
		}
	}

	// .owl-dots{
	// 	position: absolute;
	// 	z-index: 19;
	// 	bottom: 0;
	// 	left: 50%;
	// 	padding: 6px 57px 0;
		
	// 	@include media-breakpoint-between(xs,sm){
	// 		padding: 6px 15px 0;
	// 		position:relative;
	// 		text-align:center;
	// 	}

	// 	transform: translateX(-50%);
	// 	background: $cor-default;

	// 	.owl-dot{
	// 		display: inline-block;
	// 		margin: 0 5px;

	// 		span{
	// 			display: block;
	// 			width: 15px;
	// 			height: 15px;
	// 			margin: 0;
	// 			border-radius: 100%;
	// 			background: #616161;
	// 		}
			
	// 		&.active{
	// 			span{
	// 				background: #D96D00;
	// 			}
	// 		}
	// 	}
	// }
}

.produtos{
	padding-top:20px;

	.produto-content{
		border: 1px solid #595959;
		margin-bottom: 30px;
		background: #FFF;
		transition: all .48s ease;
		&:hover{
			box-shadow: 0 0 12px 5px rgba(#000, .4);
		}

		.photo{
			position: relative;
			padding-bottom: (337/535)*100%;

			.picture{
				position: absolute;
				width: 100%;
				height: 100%;

				display: flex;
				justify-content: center;
				align-items: center;
			}	
		}

		.produto-body{
			padding: 0 25px;
			text-align: center;

			.nome,
			.desc{
				font-family: "open_sanslight";
			
			}
			.nome{
				margin-top: 10px;
				margin-bottom: 10px;
				color: #3C3C3C;
				font-weight: bold;
				@include resumo(16px, 1.2, 1);
			}
			.desc{
				@include resumo(14px, 1.4, 2);
				color: #595959;
			}
		}
	}
}

.qm-somos{
	position: relative;
	padding-bottom: 80px;
	background: $bg-qmsomos;
	background-size: cover;
	padding-top:20px;

	.text{
		font-family: "open_sanslight";
		font-size: 18px;
		color: #FFF;
	}
}

.orcamento{
	position: relative;
	padding: 40px 0;
	background: $bg-orcamento;
	background-size: cover;
	
	.chamada-orcamento{
		text-align: center;

		h1{
			font-family: "oswaldregular";
			font-size: 52px;
			color: #FFBF00;
			font-style: italic;
			text-shadow: 0 0 8px #000;
		}
	}
}