$imgs: '../imagens/estrutural';
$fonts: '../fonts';

$cor-default: #FFCC2A;
$bg-header: url(#{$imgs}/bg-header.png) center top no-repeat;
$bg-footer: #222;
$bg-parceiros: url(#{$imgs}/bg-parceiros.png) 0 0 no-repeat;
$bg-qmsomos: url(#{$imgs}/bg-qmsomos.png) center top no-repeat;
$bg-orcamento: url(#{$imgs}/bg-orcamento.png) center top no-repeat;
$before-menu: url(#{$imgs}/before-menu.png) 0 0 no-repeat;
$bg-sprite: url(#{$imgs}/sprite.png) no-repeat;

$enable-flex: true;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1200px
);