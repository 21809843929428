.carousel-produtos{
	position: relative;
	border: 1px solid #222;

	.pagers-control{
		.pager{
			position: absolute;
			top: 50%;
			z-index: 19;
			transform: translateY(-50%);

			&.pager-prev{
				left: 0;
			}
			&.pager-next{
				right: 0;
			}
		}
	}

	.produto-default{
		.photo{
			position: relative;
			padding-bottom: (369/593)*100%;

			.picture{
				position: absolute;
				width: 100%;
				height: 100%;

				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
}

.desc-produto{
	font-family: "open_sanslight";
	color: #535353;
	@include resumo(15px, 1.2, 16);
}


.galeria-default{
	margin-bottom: 40px;
	transition: all .48s ease;
	&:hover{
		box-shadow: 0 0 12px 5px rgba(#000, .4);
	}
	
	.photo{
		position: relative;
		margin-bottom: 15px;
		padding-bottom: (369/593)*100%;
		
		&:after{
			content: "";
			position: absolute;
			top: calc(100% + 5px);
			left: calc(50% - 25px);
			width: 50px;
			height: 2px;
			background: #FF4000;
		}
		.picture{
			position: absolute;
			width: 100%;
			height: 100%;

			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.nome{
		text-align: center;
		font-family: "open_sanslight";
		color: #3B3B3B;
		@include resumo(18px, 1.2, 2);
	}
}

.form-black{
	padding: 15px;
	background: #383838;

	.legend{
		font-family: "open_sansregular";
		font-size: 22px;
		color: $cor-default;
		font-style: italic;
		margin-bottom: 20px;
	}

	label{
		color: #FFF;
		font-size: 16px;
	}

	input,
	select,
	textarea{
		border-radius: 0;
		min-height: 45px;
		background: #EEE;

		&:hover,
		&:focus{
			outline: none;
		}
	}

	textarea{
		resize: none;
	}
}

.download-default{
	margin-bottom: 30px;
	
	.fa,
	.icon{
		position: absolute;
		left: 50%;
		top: 10px;
		transform: translateX(-50%);
	}
	.title{
		font-style: italic;
		font-weight: bold;
		margin-bottom: 10px;
	}
	.title,
	.desc{
		font-family: "open_sanslight";
		font-size: 16px;
		color: #595959;
	}
}

.page-header{
	margin-top:40px;
	margin-bottom:20px;
}