// min-width: 1200px
@include media-breakpoint-only(xl) {
	.navbar-topo{
		.navbar-header{
			width:25%;
		}
		.navbar-right{
			width:calc(75% - 85px);
		}

		.parceiros{
			display:flex;
			justify-content: flex-end;
			align-items: center;
			position:relative;
			margin-right:-15px;
			padding-right: 30px;

			& > * {
				position:relative;
				z-index:1;
				margin-bottom:0;
			}

			&:before{
				content:'';
				display: block;
				position:absolute;
				width: 100%;
				height:100%;
				left:0;
				background: url(#{$imgs}/bg-parceiros-topo.png) left top;
			}
		}

		.navbar-address{
			display:flex;
			justify-content:space-between;
			align-items:center;

			& > *{
				display: inline-flex;
				margin-left:10px;
				align-items:center;
			}

			.icon{
				margin:0 7px;
			}
		}
	}

	.navbar-toggle{
		display: none;
	}

	#menu{
		display:flex;
		justify-content:space-between;
		align-items:flex-start;
		li{
			display: block;
		}

		a{
			padding:5px 10px 0 10px;

			&:after{
				content:'';
				height:12px;
				width:100%;
				display: block;
				transform:skew(-15deg);
			}
		}

		.active a:after,
		a:hover:after{
			background: #FFCC2A
		}
	}
}