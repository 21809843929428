.wrapper{
	min-height:100vh;
	overflow:hidden;
}

html{
	overflow-x:hidden;
}


body{
	font-family: "open_sansregular", Sans-serif, Verdana;
	font-size: 16px;
	color: #595959;
}

.container-fluid{
	max-width:1600px;
}