@import "helper/_icon.scss";

@import "midias/_xl.scss";
@import "midias/_lg.scss";
@import "midias/_md.scss";
@import "midias/_sm.scss";
@import "midias/_xs.scss";

@import "header/_header.scss";

@import "footer/_footer.scss";

@import "tipografias/_font-faces.scss";
@import "tipografias/_tipografia.scss";

@import "content/_index.scss";
@import "content/_internas.scss";

@import "helper/_util.scss";

.youtube-owl{
    .embed-responsive{
        padding-bottom: 56%;
    }
}
.segura__owl__youtube{
    padding: 0px 25px;
    margin-bottom: 30px;

    .youtube-owl.owl-carousel{
        position: relative;

        .owl-dots{
            display: none;
        }

        .owl-nav{
            &.disabled{
                display: block;
            }
            button.owl-prev,
            button.owl-next{
                width: 25px;
                background-color: #E68A00;
                color: #fff;
                height: 100%;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0px;

                &:after{
                    content: '';
                    display: block;
                    border-top: 10px solid transparent;
                    border-bottom: 10px solid transparent;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
                
                i,
                span{
                    display: none;
                }
            }
            button.owl-prev{
                left: -25px;

                &:after{
                    border-right: 10px solid #000;
                }
            }
            button.owl-next{
                right: -25px;

                &::after{
                    border-left: 10px solid #000;
                }
            }
        }
    }
}