.d-flex{
	display: flex;
	flex-wrap: wrap;
}

.jc-sb{
	justify-content: space-between;
}

.jc-c{
	justify-content: center;
}

.ai-c{
	align-items: center;
}

.ai-fe{
	align-items: flex-end;
}

.d-ib{display: inline-block;}
.unlistenner{
	list-style: none;
	margin-bottom: 0;
	padding-left: 0;
}

.btn-outline-yellow{
	min-width: 211px;
	font-family: "open_sanslight";
	font-size: 26px;
	color: #FFF;
	border-radius: 0;
	background: transparent;
	border: 1px solid #FFBF00;
	&:hover{
		background: #FFBF00;
		@include  gradient-y(#FFBF00, darken(#FFBF00, 5%));
	}
}

.btn-yellow{
	min-width: 211px;
	font-family: "open_sanslight";
	font-size: 26px;
	color: #000;
	border-radius: 0;
	background: #FFBF00;
	&:hover{
		@include  gradient-y(#FFBF00, darken(#FFBF00, 5%));
	}
}

.border-bottom{
	position: relative;

	&:after{
		content: "";
		display: inline-block;
		width: 100%;
		height: 3px;

		position: absolute;
		top: 100%;
		right: 0;
		border-radius: 15px;
		@include gradient-directional(#222222, $cor-default, 90deg);
	}
}

.border-bottom-yellow{
	position: relative;

	&:after{
		content: "";
		display: inline-block;
		width: 90%;
		height: 3px;

		position: absolute;
		top: 100%;
		right: 0;
		border-radius: 15px;
		@include gradient-directional(#FFF, #D9980B, 90deg);
	}
}

.c-yellow{
	color: #FFBF00;
}

.c-white{
	color: #FFF;
}

.btn-outline-white{
	font-family: "oswaldlight";
	font-size: 32px;
	color: #FFF;
	font-weight: bold;
	border-radius: 0;
	padding: 6px 25px 0;
	border: 1px solid #FFF;
	&:hover{
		color: #333;
		background: #FFF;
	}
}


.btn-outline-black{
	font-family: "open_sanslight";
	font-size: 14px;
	color: #595959;
	font-weight: bold;
	min-width: 155px;
	border-radius: 25px;
	border: 1px solid #595959;
	&:hover{
		color: #FFF;
		background: #595959;
	}
}

.btn-outline-orange{
	font-family: "open_sanslight";
	font-size: 14px;
	color: #F58333;
	font-weight: bold;
	min-width: 155px;
	border-radius: 25px;
	border: 1px solid #F58333;
	&:hover{
		color: #FFF;
		background: #F58333;
	}
}

.btn{
	// @media (max-width: $screen-xs-max){
	// 	width: 100%;
	// 	margin-bottom: 10px;
	// 	white-space: wrap;
	// }
}

#modal-orcamento,
.modal-open{
	padding-right: 0 !important;
}

#modal-orcamento{
	.modal-dialog{
		width:1150px;
		max-width:calc(100% - 30px);
		margin-top:110px;

		.modal-content{
			background: #383838;
			
			.modal-header{
				position: absolute;
				top: 0;
				z-index: 19;
				right: 0;
				border-bottom: none;
			}

			.modal-body{
				.logo{
					position: relative;
					
					@media (min-width: 768px){
						top: -80px;
						margin-bottom: -160px;
					}
				}

				label{
					color: #FFF;
					font-size: 16px;
				}

				input,
				select,
				textarea{
					border-radius: 0;
					min-height: 45px;
					background: #EEE;

					&:hover,
					&:focus{
						outline: none;
					}
				}

				textarea{
					resize: none;
				}
			}
		}
	}
}

.btn-fixed-scroll{
	position: absolute;
	right: 0;
	opacity: 0;

	z-index: 1999;
	transition: all .106s ease;

	&.active{
		opacity: 1;
	}
}

.row{
	position: relative;
}

.img-responsive{
	max-width:100%;
	height:auto;
	display: inline-block;
}

.btn{
	white-space: normal;
}

.modal-backdrop.fade.in{
	opacity: 0.5 !important;
}

.modal.fade.in{
	opacity: 1 !important;
}