$br: -59px -54px 48px 33px;
$button-angle-up: -63px 0px 44px 44px;
$en: -117px 0px 48px 33px;
$es: 0px -54px 49px 33px;
$ic-download: 0px 0px 53px 44px;
$ic-email: -117px -43px 36px 36px;
$ic-facebook: -92px -97px 33px 32px;
$ic-locate: 0px -97px 36px 36px;
$ic-pager-gray-next: 0px -143px 29px 25px;
$ic-pager-gray-prev: -39px -143px 29px 25px;
$ic-pager-square-next: -175px -42px 29px 33px;
$ic-pager-square-prev: -175px -85px 29px 33px;
$ic-phone: -46px -97px 36px 36px;
$ic-youtube: -175px 0px 33px 32px;
$whatsapp: -135px -97px 27px 27px;

@mixin sprite-width($sprite) {
	width: nth($sprite, 3);
}
@mixin sprite-height($sprite) {
	height: nth($sprite, 4);
}
@function sprite-width($sprite) {
	@return nth($sprite, 3);
}
@function sprite-height($sprite) {
	@return nth($sprite, 4);
}
@mixin sprite-position($sprite) {
	$sprite-offset-x: nth($sprite, 1);
	$sprite-offset-y: nth($sprite, 2);
	background-position: $sprite-offset-x $sprite-offset-y;
}
@mixin sprite($sprite, $display: block) {
	@include sprite-position($sprite);
	background-repeat: no-repeat;
	overflow: hidden;
	display: $display;
	@include sprite-width($sprite);
	@include sprite-height($sprite);
}
.icon {
	background-image: url(../images/sprite.png);
}
