// min-width: 768px and max-width: 991px
@include media-breakpoint-only(md) {
	.navbar-topo{
		position: relative;

		.navbar-header{
			width:100%;
			text-align:center;
			@include clearfix;;
		}

		.logo{
			max-width:30%;
			float:left;
		}

		.navbar-right{
			width:100%;
		}

		.parceiros{
			display:flex;
			align-items:center;
			justify-content:space-between;
			position:absolute;
			width:65%;
			right:0;
			top:20px;
			background:#FFF;

			figure{
				margin-bottom:0;
				max-width:23%;
			}
		}
	}

	.navbar-address{
		display:flex;
		flex-wrap:wrap;
		justify-content:space-between;
		font-size:13px !important;

		& > *{
			display:inline-flex;
			align-items:center;
			margin:0 10px 10px 10px;
		}

		.redes-sociais{
			justify-content:flex-end;
			
			.icon{
				margin-left:10px;
			}
		}

	}

	.navbar-toggle{
		display: none;
	}

	#menu{
		display:flex;
		justify-content:space-between;

		li{
			display: block;
		}

		a{
			padding:5px 10px 0 10px;
			font-size:16px !important;

			&:after{
				content:'';
				height:12px;
				width:100%;
				display: block;
				transform:skew(-15deg);
			}
		}

		.active a:after,
		a:hover:after{
			background: #FFCC2A
		}
	}

	// CONTEUDO
	.produto-content{
		.btn{
			width:100%;

			& + .btn{
				margin-top: 15px;
			}
		}
	}
}