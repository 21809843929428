.btn__whatsapp{
    position: fixed;
    left: 15px;
    bottom: 15px;
    width: 60px;
    height: 60px;
    z-index: 999;
    $bg: #4caf50;

    &:before{
        content:'';
        position: absolute;
        width: 100%;
        height: 100%;
        background: $bg;
        border-radius: 50%;
        left: 0;
        top: 0;
        z-index: 0;
        animation: whatsappPulse 1s linear infinite;
    }

    .fa{
        position: absolute;
        width: 100%;
        height: 100%;
        color: #FFF;
        background: $bg;
        border-radius: 50%;
        font-size: 40px;
        z-index: 1;
        left: 0;
        top: 0;
        box-shadow: 0 0 8px rgba(#000,.6);

        &:before{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
        }
    }
}

@keyframes whatsappPulse{
    0% {
        transform: scale(1);
        opacity: 0.6;
    }

    100% {
        transform: scale(1.5);
        opacity: 0;
    }
}