@include media-breakpoint-only(xs){
	// Celphones - < 768

	// TOPO
	.navbar-topo{
		background-position:left top !important;

		.navbar-header{
			width:100%;
			text-align:center;
		}

		.navbar-right{
			width:100%;
		}


		.parceiros{
			margin-left:-15px;
			margin-right:-15px;
			background:#FFF;
			display:flex;
			justify-content:space-between;

			figure{
				margin-bottom:0;
				max-width:23%;
			}
		}

	}

	.navbar-address{
		// text-align:center;

		span{
			display: none;
		}
	}

	.navbar-nav{
		position:relative;

		.navbar-toggle{
			position:absolute;
			right:0;
			bottom:100%;
			margin-bottom:40px;
		}
	}
	
	.affix{
		.navbar-fixed{
			position:fixed;
			width:100%;
			top:0;
			left:0;
			z-index:999;
			animation:fadeInDown 0.4s;
			background: #000000;
			padding:10px 15px;
			text-align:right;
		}

		.navbar-toggle{
			position:relative;
			bottom:0;
			margin-bottom:0;
		}
	}

	#menu{
		margin-left:-15px;
		margin-right:-15px;
		text-align:left;
		background: #FFCC2A;
		margin-top:10px;
		border-top:1px solid lighten(#000000, 15%);

		a{
			padding:5px 15px;
		}

		.active a,
		a:hover{
			background: #F89931;
		}
	}

	// CONTEUDO
	.produto-content{
		.btn{
			width:100%;

			& + .btn{
				margin-top: 15px;
			}
		}
	}

	#modal-orcamento{
		.modal-header{
			position:relative !important;
		}
	}
}